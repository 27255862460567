.ReferralCard {
    text-align: center;
    margin: 20px 100px;
}

.ReferralRow {
    color: dimgray;
    background: whitesmoke;
    padding: 10px 0px;
}

.ReferralAccordion {
    background: lightgray;
}